@import '../../colors.scss';

.main-navbar {
  width: 100%;
  height: 64px;
  background-color: $secondaryBackgroundColor;
  border-bottom: 1px solid #D9D9D9;
  text-align: left;
  .collapse {
    display: inline-block;
    width: 64px;
    height: 64px;
    background-image: url('./icons/collapse.svg');
    background-position: center center;
    background-repeat: no-repeat;
    &:hover {
      background-color: $secondaryBackgroundHighlightColor;
    }
  }
  .logout {
    float: right;
    padding: 22px;
    cursor: pointer;
    color: $secondaryTextColor;
  }
}