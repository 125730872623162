$primaryBackgroundColor: #f0f2f5;
$secondaryBackgroundColor: #ffffff;
$secondaryBackgroundHighlightColor: #f5f5f5;

$primaryTextColor: #595959;
$secondaryTextColor: #889998;
$thirdTextColor: black;
$primaryLinkTextColor: #1da1f2;

$logoTextColor: #6979f8;
$sidebarHoverLightColor: #f0fdf2;
$sidebarHoverDarkColor: #a8e2b2;
$sidebarActiveLightColor: #e6f7ff;
$sidebarActiveDarkColor: #1890ff;
$sidebarShadowColor: black;

$columnBackgroundColor: #15202b;
$columnSeperatorColor: black;

$tableHeaderBackgroundColor: #fafafa;
$tableHeaderBottomBorderColor: #e8e8e8;

$activeLinkColor: #096DD9;

$buttonBackgroundColor: #40A9FF;
$buttonTextColor: white;

$textFieldBorderColor: #e8e8e8;
$textFieldBorderFocusColor: #e9e9e9;
$textFieldBackgroundColor: #ffffff;

$overlayBackgroundColor: rgba(0, 0, 0, 0.75);

$fatalBackgroundColor: #CA001F;
$warningBackgroundColor: #F2994A;
$fatalTextColor: white;
$warningTextColor: white;