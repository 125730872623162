@import '../../colors.scss';

.user-card-container {
  overflow: scroll;
  background-color: $overlayBackgroundColor;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
  .user-card {
    background-color: $secondaryBackgroundColor;
    color: $thirdTextColor;
    width: 500px;
    position: absolute;
    top: 50px;
    left: 50%;
    transform: TranslateX(-50%); 
    border-radius: 11px;
    box-shadow: 0px 0px 15px -2px rgba(0, 0, 0, 0.4);
    .small-image {
      width: 80px;
      height: 80px;
      padding-top: 5px;
      margin-right: 5px;
    }
    .color {
      height: 118px;
      border-top-left-radius: 11px;
      border-top-right-radius: 11px;
      position: relative;
      &.red { background-color: #CA001F }
      &.purple { background-color: #6979F8 }
      .image {
        width: 140px;
        height: 141px;
        position: absolute;
        left: 50%;
        top: 30px;
        transform: translateX(-50%);
        background-size: cover;
        border-radius: 78px;
        border: 10px solid white;
      }
    }
    h1 {
      margin: 0;
      margin-top: 0px;
      padding: 0;
      margin-top: 82px;
      font-weight: normal;
    }
    .body {
      padding: 30px;
      padding-top: 10px;
      .row {
        padding-top: 20px;
        text-align: left;
        &.icon {
          padding: 12px;
          padding-left: 50px;
          background-repeat: no-repeat;
          background-position: 20px center;
          &.email { background-image: url(./icons/email.svg) }
          &.location { background-image: url(./icons/location.svg) }
          &.bio { background-image: url(./icons/person.svg) }
          &.interests { background-image: url(./icons/dots.svg) }
          &.cake { background-image: url(./icons/cake.svg); background-size: 23px auto; }
        }
      }
    }
    .close {
      position: absolute;
      top: -14px;
      right: -14px;
      width: 40px;
      height: 40px;
      font-weight: 200;
      border-radius: 25px;
      background-color: $secondaryBackgroundColor;
      border: 1px solid #c3cad4;
      font-size: 28px;
      color: #747980;
      box-shadow: 0px 0px 15px -1px rgba(0, 0, 0, 0.5);
      cursor: pointer;
    }

    .buttons.row {
      text-align: center;
      padding-top: 35px;
      &.users-main { padding-top: 18px }
    }

    button {
      border: none;
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: 14px;
      width: 125px;
      margin-left: auto;
      margin-right: auto;
      &.danger {
        background-color: $fatalBackgroundColor;
        color: $fatalTextColor;
      }
      &.warning {
        background-color: $warningBackgroundColor;
        color: $warningTextColor;
      }
    }

    /* reported card */

    .ban-btn {
      transform: translateX(-9%);
    }
    .suspend-btn {
      transform: translateX(9%);
    }

    .report-reason {
      color: #CA001F;
    }
    .email {
      position: relative;
      .star {
        width: 25px;
        height: 25px;
        background-repeat: no-repeat;
        background-image: url(./icons/star.svg);
        position: absolute;
        top: 13px;
        right: 0;
      }
    }

  }
}

.instagram-username { 
  background-image: url(./icons/instagram.svg);
}

.gender { 
  background-image: url(./icons/gender.svg);
}

