@import "../../colors.scss";

.login-pane {
  text-align: center;
  background-color: $secondaryBackgroundColor;
  width: 210px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.14);
  input {
    margin-top: 10px;
    width: calc(100% - 56px);
  }
  .std-button {
    margin-top: 10px;
    margin-bottom: 21px;
    width: calc(100% - 38px);
    padding: 10px;
  }
  .error-box {
    text-align: left;
    padding: 10px;
    padding-top: 7px;
    padding-bottom: 7px;
    background-color: #ffe1e0;
    margin: 20px;
    margin-top: 0px;
    margin-bottom: 8px;
    font-size: 11px;
    border-radius: 4px;
  }
}