@import '../../colors.scss';

.configable-table {
  width: 100%;
  border-collapse: collapse;
  thead tr td {
    font-weight: bold;
  }
  thead tr {
    background-color: $tableHeaderBackgroundColor;
  }
  td {
    padding: 15px;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
  }
  tr {
    border-bottom: 1px solid $tableHeaderBottomBorderColor;
  }
  tbody tr:last-child {
    border-bottom: none;
  }
}