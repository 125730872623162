.column-view {
  background-color: $columnBackgroundColor;
  width: 300px;
  height: calc(100% - 100px);

  .post {
    padding: 15px;
    padding-top: 10px;
    padding-bottom: 8px;
    border-bottom: 1px solid $columnSeperatorColor;
    text-align: left;

    .title {
      font-size: 13px;
      text-decoration: none;
      color: $primaryTextColor;
    }

    .author {
      font-size: 11px;
      text-decoration: none;
      color: $secondaryTextColor;
    }

  }

}
