@import '../../colors.scss';
@import '../../fonts/Futura/Futura.scss';

$sidebarWidth: 186px;

.main-sidebar {
  text-align: left;
  background-color: $secondaryBackgroundColor;
  width: $sidebarWidth;
  height: 100%;
  box-shadow: 0px 0px 12px -9px $sidebarShadowColor;
  transition: margin-left 0.4s;

  &.hidden {
    margin-left: -$sidebarWidth;
  }

  .options {
    list-style-type: none;

    ul {
      margin: 0;
      padding: 0;

      a {
        display: block;
        position: relative;
        text-decoration: none;
        color: $primaryTextColor;
        padding: 12px;
        padding-left: 42px;
        background-repeat: no-repeat;
        background-position: 20px center;

        &:hover {
          background-color: $sidebarHoverLightColor;
          color: $sidebarHoverDarkColor;
          &:after {
            background-color: $sidebarHoverDarkColor;
          }
        }

        &:after {
          content: "";
          position: absolute;
          display: block;
          top: 0;
          bottom: 0;
          right: 0;
          width: 2px;
        }
      }
      &.active a {
        background-color: $sidebarActiveLightColor;
        color: $sidebarActiveDarkColor;
        &:after {
          background-color: $sidebarActiveDarkColor;
        }
      }

      @mixin icon($name) {
        &.#{$name} a { background-image: url("./icons/#{$name}.svg") }
        &.#{$name} a:hover { background-image: url("./icons/#{$name}-hover.svg") }
        &.active.#{$name} a { background-image: url("./icons/#{$name}-active.svg") }
        &.#{$name} .prefetch .hover { background-image: url("./icons/#{$name}-hover.svg") }
        &.#{$name} .prefetch .active { background-image: url("./icons/#{$name}-active.svg") }
      }

      @include icon("advisors");
      @include icon("stats");
      @include icon("feedback");
      @include icon("applications");
      @include icon("transactions");
      @include icon("users");

      &.applications a {
        background-size: 14px auto;
        background-position: 17px center;
      }


    }

  }

}
