.advisor-actions {
  button {
    margin-right: 5px !important;
  }
}

.about-me {
  background-image: url(./../UserCard/icons/person.svg);
}

.expertise {
  background-image: url(./../UserCard/icons/dots.svg);
}

.profile-images {
  text-align: center !important;
}

.profile-images img {
  width: auto;
  height: 100px;
}
