@import "../../colors.scss";

.tabbed-page {

  .header-container h1 {
    background-color: $secondaryBackgroundColor;
    margin: 0;
    text-align: left;
    color: $primaryTextColor;
    font-weight: bold;
    font-size: 16px;
    padding: 15px;
    padding-left: 20px;
    padding-top: 0;
  }

  .tab-names {
    background-color: $secondaryBackgroundColor;
    text-align: left;
    padding-left: 20px;
    padding-top: 8px;
    a {
      display: inline-block;
      text-decoration: none;
      color: $primaryTextColor;
      padding-bottom: 13px;
      margin-right: 25px;
      outline: none !important;
      &.active {
        color: $activeLinkColor;
        font-weight: bold;
        border-bottom: 2px solid $activeLinkColor;
      }
    }
  }

}