@font-face {
    font-family: 'Futura URW';
    src: url('./FuturaT-Medi.woff2') format('woff2'),
        url('./FuturaT-Medi.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Futura';
    src: url('./Futura-Medium.woff2') format('woff2'),
        url('./Futura-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

