@import "colors.scss";
@import "./Column/ColumnStyle.scss";

html, body, #root, .App {
  height: 100%;
}

body {
  background-color: $primaryBackgroundColor;
  color: $primaryTextColor;
  font-size: 13px;

  a {
    color: $primaryLinkTextColor;
  }

  &.fullscreen-content {
    .App .main-sidebar {
      z-index: 1;
    }
  }

}

.App {
  .login-guard {
    text-align: center;
    display: flex;
    height: 100%;
  }
  .main-sidebar {
    z-index: 2;
  }
  .right-side {
    flex: 1;
    z-index: 1;
  }
  .main-pane {
    background-color: $secondaryBackgroundColor;
    margin: 20px;
    padding: 20px;
    text-align: left;
    .search {
      padding: 8px;
      margin-bottom: 20px;
      border: 1px solid $textFieldBorderColor;
      border-radius: 3px;
      &:focus, &:active {
        border: 1px solid $textFieldBorderFocusColor ;
        outline: none;
      }
    }
  }
  .main-pane-header {
    background-color: $secondaryBackgroundColor;
    margin: 0;
    text-align: left;
    color: $secondaryTextColor;
    font-weight: normal;
    font-size: 13px;
    padding: 15px;
    padding-left: 20px;
    padding-bottom: 10px;
  }
  .std-button {
    background-color: $buttonBackgroundColor;
    color: $buttonTextColor;
    display: inline-block;
    padding: 5px;
    padding-left: 8px;
    padding-right: 8px;
    margin: 0;
    border: none;
    border-radius: 3px;
    &.logo-color {
      background-color: $logoTextColor;
    }
  }
  .logo {
    color: $logoTextColor;
    font-family: 'Futura URW';
    font-size: 38px;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 10px;
  }
  input {
    padding: 8px;
    border: 1px solid $textFieldBorderColor;
    background-color: $textFieldBackgroundColor;
    border-radius: 3px;
    filter: none;
    &:focus, &:active {
      border: 1px solid $textFieldBorderFocusColor;
      outline: none;
    }
  }

  .row-spinner {
    width: 100%;
    height: 100px;
    background-image: url(/loading-spinner.gif);
    background-size: 30px 30px;
    background-repeat: no-repeat;
    background-position: center center;
  }

}
